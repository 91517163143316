<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col>
          <p class="title font-weight-bold ma-0">Reports</p>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Reports",
};
</script>