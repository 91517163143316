var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"lg":"8","md":"8","sm":"12","xs":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-start pb-3",attrs:{"lg":"8","md":"8","sm":"12","xs":"12"}},[_c('p',{staticClass:"title font-weight-bold ma-0"},[_vm._v(" Customer Complaints - Weekly Status Report ")]),_c('p',{staticClass:"caption font-weight-bold ma-0 ml-2"},[_vm._v(" "+_vm._s("(" + _vm.$moment(new Date()).format("MMMM Do YYYY, h:mm a") + ")")+" ")])]),_c('v-col',{staticClass:"d-flex align-right justify-end"},[_c('v-btn',{attrs:{"text":"","small":"","raised":""},on:{"click":_vm.generatePDF}},[_vm._v(" Print "),_c('v-icon',{attrs:{"color":"#B00020"}},[_vm._v(" mdi-file-pdf-outline ")])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('p',{staticClass:"subtitle font-weight-bold mb-1"},[_vm._v(" Customer Complaints Outstanding more than 21 days ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.complaints21DaysOld,"dense":"","disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.complaintNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaintNumber))])]}},{key:"item.complaint_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaint_type.type))])]}},{key:"item.complaintDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaintDate))])]}},{key:"item.complaint_status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.complaint_status.status)+" ")])]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('p',{staticClass:"subtitle font-weight-bold mb-1"},[_vm._v(" Customer Complaints Outstanding more than 14 days ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.complaints14To21DaysOld,"dense":"","disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.complaintNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaintNumber))])]}},{key:"item.complaint_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaint_type.type))])]}},{key:"item.complaintDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaintDate))])]}},{key:"item.complaint_status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.complaint_status.status)+" ")])]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('p',{staticClass:"subtitle font-weight-bold mb-1"},[_vm._v(" Customer Complaints Outstanding less than 14 days ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.complaints14DaysOld,"dense":"","disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.complaintNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaintNumber))])]}},{key:"item.complaint_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaint_type.type))])]}},{key:"item.complaintDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.complaintDate))])]}},{key:"item.complaint_status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.complaint_status.status)+" ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }