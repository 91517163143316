<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="4" md="4" sm="12" xs="12">
          <v-card
            max-width="550"
            height="40vh"
            :color="getColorScheme.lightColor"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold text-uppercase">
                    Parking Violations
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showViolationWeeklyReport">
                    Weekly Status Report
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" md="4" sm="12" xs="12">
          <v-card
            max-width="550"
            height="40vh"
            :color="getColorScheme.lightColor"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold text-uppercase">
                    Customer Complaints
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showComplaintWeeklyReport">
                    Weekly Status Report
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" md="4" sm="12" xs="12">
          <v-card
            max-width="550"
            height="40vh"
            :color="getColorScheme.lightColor"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold text-uppercase">
                    Yard Orders
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showYOWeeklyReport">
                    Weekly Status Report
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="4" md="4" sm="12" xs="12">
          <v-card
            max-width="550"
            height="40vh"
            :color="getColorScheme.lightColor"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold text-uppercase">
                    Search
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showGlobalSearch">
                    Global Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as constants from "../../shared/constants";

export default {
  name: "report-options",
  data() {
    return {
      clrBtn: constants.CLR_BTN,
    };
  },
  computed: {
    ...mapGetters(["getColorScheme"]),
  },
  methods: {
    //Search
    showViolationWeeklyReport() {
      this.$router.push({ name: "violationWeeklyReport" });
    },
    showComplaintWeeklyReport() {
      this.$router.push({ name: "complaintWeeklyReport" });
    },
    showYOWeeklyReport() {
      this.$router.push({ name: "yardorderWeeklyReport" });
    },
    showGlobalSearch() {
      this.$router.push({ name: "globalSearch" });
    },
  },
};
</script>
