<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col lg="10" md="10" sm="12" xs="12">
        <v-card outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col lg="8" md="8" sm="12" xs="12" class="pb-3">
                <p class="title font-weight-bold ma-0">Global Search</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="6" md="6" sm="6" class="pr-2">
                <v-text-field
                  v-model="globalSearch"
                  label="Global Search"
                  append-icon="search"
                  outlined
                  dense
                  @keyup.enter="searchAllModules(globalSearch)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="6">
                <v-btn
                  :color="getColorScheme.darkColor"
                  dark
                  small
                  raised
                  v-on:click="searchAllModules(globalSearch)"
                  >Go
                </v-btn>
              </v-col>
              <p class="error subtitle-2 font-weight-bold" v-if="error">
                {{ error }}
              </p>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6">
                <p class="subtitle font-weight-bold">Parking Violations</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headersViolation"
                  :items="filterSearchResults('violation')"
                  class="elevation-1"
                  dense
                >
                  <template v-slot:no-data v-if="searchPeformed">
                    <span class="red--text">No results found.</span>
                  </template>
                  <template v-slot:[`item.ticketNumber`]="{ item }">
                    <span class="caption">{{ item.ticketNumber }}</span>
                  </template>
                  <template v-slot:[`item.licensePlate`]="{ item }">
                    <span class="caption">{{ item.licensePlate }}</span>
                  </template>
                  <template v-slot:[`item.vehicleMake`]="{ item }">
                    <span class="caption">{{ item.vehicleMake }}</span>
                  </template>
                  <template v-slot:[`item.locationAddress`]="{ item }">
                    <span class="caption">{{ item.locationAddress }}</span>
                  </template>
                  <template v-slot:[`item.note`]="{ item }">
                    <span class="caption">{{ item.note }}</span>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-row no-gutters>
                      <v-col>
                        <v-btn
                          text
                          small
                          :color="getColorScheme.darkColor"
                          @click="openViolationDetails(item)"
                        >
                          Show Details
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" class="pt-5">
                <p class="subtitle font-weight-bold">Customer Complaints</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headersComplaint"
                  :items="filterSearchResults('complaint')"
                  class="elevation-1"
                  dense
                >
                  <template v-slot:no-data v-if="searchPeformed">
                    <span class="red--text">No results found.</span>
                  </template>
                  <template v-slot:[`item.complaintNumber`]="{ item }">
                    <span class="caption">{{ item.complaintNumber }}</span>
                  </template>
                  <template v-slot:[`item.callerName`]="{ item }">
                    <span class="caption">
                      {{ item.callerName }}
                    </span>
                  </template>
                  <template v-slot:[`item.locationAddress`]="{ item }">
                    <span class="caption">{{ item.locationAddress }}</span>
                  </template>
                  <template v-slot:[`item.description`]="{ item }">
                    <span class="caption">{{ item.description }}</span>
                  </template>
                  <template v-slot:[`item.investigativenotes`]="{ item }">
                    <span
                      class="caption"
                      v-for="(notes, index) in item.investigativenotes"
                      :key="index"
                    >
                      <p>{{ index + 1 }}) {{ notes.note }}</p>
                    </span>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-row no-gutters>
                      <v-col>
                        <v-btn
                          text
                          small
                          :color="getColorScheme.darkColor"
                          @click="openComplaintDetails(item)"
                        >
                          Show Details
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" class="pt-5">
                <p class="subtitle font-weight-bold">Yard Orders</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headersYardOrder"
                  :items="filterSearchResults('yardorder')"
                  class="elevation-1"
                  dense
                >
                  <template v-slot:no-data v-if="searchPeformed">
                    <span class="red--text">No results found.</span>
                  </template>
                  <template v-slot:[`item.orderNumber`]="{ item }">
                    <span class="caption">{{ item.orderNumber }}</span>
                  </template>
                  <template v-slot:[`item.registeredOwner`]="{ item }">
                    <span class="caption">
                      {{ item.registeredOwner }}
                    </span>
                  </template>
                  <template v-slot:[`item.locationAddress`]="{ item }">
                    <span class="caption">{{ item.locationAddress }}</span>
                  </template>
                  <template v-slot:[`item.descriptionOfWork`]="{ item }">
                    <span class="caption">{{ item.descriptionOfWork }}</span>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-row no-gutters>
                      <v-col>
                        <v-btn
                          text
                          small
                          :color="getColorScheme.darkColor"
                          @click="openYardOrderDetails(item)"
                        >
                          Show Details
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "global-search",
  data() {
    return {
      globalSearch: "",
      error: "",
      searchPeformed: false,
      headersViolation: [
        {
          text: "Ticket #",
          value: "ticketNumber",
          width: "10%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "License Plate",
          value: "licensePlate",
          width: "10%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Location",
          value: "locationAddress",
          width: "20%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Vehicle Make",
          value: "vehicleMake",
          width: "15%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Note",
          value: "note",
          width: "35%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Action(s)",
          value: "action",
          width: "10%",
          align: "center",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
      headersComplaint: [
        {
          text: "Complaint #",
          value: "complaintNumber",
          width: "10%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Caller Name",
          value: "callerName",
          width: "10%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Location",
          value: "locationAddress",
          width: "20%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Description",
          value: "description",
          width: "23%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Investigative Notes",
          value: "investigativenotes",
          width: "27%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Action(s)",
          value: "action",
          width: "10%",
          align: "center",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
      headersYardOrder: [
        {
          text: "Order #",
          value: "orderNumber",
          width: "8%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Registered Owner",
          value: "registeredOwner",
          width: "12%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Location",
          value: "locationAddress",
          width: "20%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Description of Work",
          value: "descriptionOfWork",
          width: "50%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Action(s)",
          value: "action",
          width: "10%",
          align: "center",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(["reportSearchResults"]),
    ...mapGetters(["getColorScheme"]),
  },
  methods: {
    ...mapActions(["getReportSearchResultsAction"]),
    async searchAllModules(searchText) {
      if (searchText == "") {
        this.error = "Please enter global search text.";
        return;
      }
      this.error = "";
      await this.getReportSearchResultsAction(searchText);
      this.searchPeformed = true;
    },

    filterSearchResults(moduleVal) {
      let filteredRecords = [];
      filteredRecords = this.reportSearchResults.filter((searchResult) => {
        if (
          searchResult != null &&
          searchResult != undefined &&
          searchResult.moduleType.toUpperCase().match(moduleVal.toUpperCase())
        ) {
          return searchResult;
        }
      });
      return filteredRecords;
    },

    openViolationDetails(violation) {
      this.$router.push({
        name: "violations",
        params: { openViolationId: violation.id },
      });
      this.$store.state.reportSearchResults = [];
    },
    openComplaintDetails(complaint) {
      this.$router.push({
        name: "complaints",
        params: { openComplaintId: complaint.id },
      });
      this.$store.state.reportSearchResults = [];
    },
    openYardOrderDetails(yardorder) {
      this.$router.push({
        name: "yardorders",
        params: { openYardOrderId: yardorder.id },
      });
    },
  },
  destroyed: function() {
    this.$store.state.reportSearchResults = [];
  },
};
</script>
