<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col lg="8" md="8" sm="12" xs="12">
        <v-card outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col
                lg="8"
                md="8"
                sm="12"
                xs="12"
                class="d-flex align-center justify-start pb-3"
              >
                <p class="title font-weight-bold ma-0">
                  Parking Violations - Weekly Status Report
                </p>
                <p class="caption font-weight-bold ma-0 ml-2">
                  {{
                    "(" +
                      $moment(new Date()).format("MMMM Do YYYY, h:mm a") +
                      ")"
                  }}
                </p>
              </v-col>
              <v-col class="d-flex align-right justify-end">
                <v-btn text small raised @click="generatePDF">
                  Print
                  <v-icon color="#B00020">
                    mdi-file-pdf-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6">
                <p class="subtitle font-weight-bold mb-1">
                  Parking Violations Outstanding more than 21 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers1"
                  :items="violations21DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.ticketNumber`]="{ item }">
                    <span class="caption">{{ item.ticketNumber }}</span>
                  </template>
                  <template v-slot:[`item.licensePlate`]="{ item }">
                    <span class="caption">{{ item.licensePlate }}</span>
                  </template>
                  <template v-slot:[`item.dateIssued`]="{ item }">
                    <span class="caption">{{ item.dateIssued }}</span>
                  </template>
                  <template v-slot:[`item.violationTypes`]="{ item }">
                    <v-row no-gutters>
                      <v-col cols="2">
                        <span class="caption">
                          {{ "$" + getFineAmt(item.violationTypes) }}
                        </span>
                      </v-col>
                      <v-col>
                        <v-icon color="error" small>
                          mdi-gavel mdi-flip-h
                        </v-icon></v-col
                      >
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" class="pt-5">
                <p class="subtitle font-weight-bold mb-1">
                  Parking Violations Outstanding more than 14 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers2"
                  :items="violations14To21DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.ticketNumber`]="{ item }">
                    <span class="caption">{{ item.ticketNumber }}</span>
                  </template>
                  <template v-slot:[`item.licensePlate`]="{ item }">
                    <span class="caption">{{ item.licensePlate }}</span>
                  </template>
                  <template v-slot:[`item.dateIssued`]="{ item }">
                    <span class="caption">{{ item.dateIssued }}</span>
                  </template>
                  <template v-slot:[`item.violationTypes`]="{ item }">
                    <span class="caption">
                      {{ "$" + getFineAmt(item.violationTypes) }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" class="pt-5">
                <p class="subtitle font-weight-bold mb-1">
                  Parking Violations Outstanding less than 14 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers3"
                  :items="violations14DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.ticketNumber`]="{ item }">
                    <span class="caption">{{ item.ticketNumber }}</span>
                  </template>
                  <template v-slot:[`item.licensePlate`]="{ item }">
                    <span class="caption">{{ item.licensePlate }}</span>
                  </template>
                  <template v-slot:[`item.dateIssued`]="{ item }">
                    <span class="caption">{{ item.dateIssued }}</span>
                  </template>
                  <template v-slot:[`item.violationTypes`]="{ item }">
                    <span class="caption">
                      {{ "$" + getEarlyAmt(item.violationTypes) }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as commonjs from "../../shared/common";
import * as constants from "../../shared/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "violation-weekly-report",
  data() {
    return {
      headers1: [
        {
          text: "Ticket #",
          value: "ticketNumber",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "License Plate",
          value: "licensePlate",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Date of Issue",
          value: "dateIssued",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Fine Amount (Set Amount)",
          value: "violationTypes",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
      headers2: [
        {
          text: "Ticket #",
          value: "ticketNumber",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "License Plate",
          value: "licensePlate",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Date of Issue",
          value: "dateIssued",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Fine Amount (Set Amount)",
          value: "violationTypes",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
      headers3: [
        {
          text: "Ticket #",
          value: "ticketNumber",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "License Plate",
          value: "licensePlate",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Date of Issue",
          value: "dateIssued",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Fine Amount (Early Amount)",
          value: "violationTypes",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
      violations: [],
      violations21DaysOld: [],
      violations14To21DaysOld: [],
      violations14DaysOld: [],
    };
  },
  async created() {
    await this.getViolationsAction();
    this.violations = this.$store.state.violations;

    await this.filterResults();
  },
  computed: {
    ...mapGetters(["getColorScheme"]),
  },
  methods: {
    ...mapActions(["getViolationsAction"]),

    filterResults() {
      let dateSub21Days = this.$moment(new Date(), "MMM Do YYYY")
        .subtract(21, "days")
        .toISOString()
        .substr(0, 10);
      let dateSub14Days = this.$moment(new Date(), "MMM Do YYYY")
        .subtract(14, "days")
        .toISOString()
        .substr(0, 10);

      //filter results based on Issue date
      this.violations21DaysOld = this.violations.filter(
        (violation) =>
          violation.isWarning == false &&
          violation.isPaid == false &&
          this.$moment(violation.dateIssued).isBefore(dateSub21Days)
      );

      this.violations14To21DaysOld = this.violations.filter(
        (violation) =>
          violation.isWarning == false &&
          violation.isPaid == false &&
          this.$moment(violation.dateIssued).isSameOrAfter(dateSub21Days) &&
          this.$moment(violation.dateIssued).isSameOrBefore(dateSub14Days)
      );

      this.violations14DaysOld = this.violations.filter(
        (violation) =>
          violation.isWarning == false &&
          violation.isPaid == false &&
          this.$moment(violation.dateIssued).isAfter(dateSub14Days)
      );
    },

    getEarlyAmt(violationTypes) {
      let totEarlyAmt = 0.0;
      violationTypes.forEach(function(entry) {
        totEarlyAmt += entry.earlyPmtAmt;
      });
      return totEarlyAmt;
    },
    getFineAmt(violationTypes) {
      let totFineAmt = 0.0;
      violationTypes.forEach(function(entry) {
        totFineAmt += entry.setFineAmt;
      });
      return totFineAmt;
    },

    async generatePDF() {
      let self = this;
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let columns1 = [];
      this.headers1.forEach((col) => columns1.push(col.text));

      let columns2 = [];
      this.headers2.forEach((col) => columns2.push(col.text));

      let columns3 = [];
      this.headers3.forEach((col) => columns3.push(col.text));

      var docDefinition = {
        pageMargins: [25, 50, 25, 50],
        header: {
          margin: 25,
          columns: [
            {
              image: await commonjs.getBase64ImageFromURL(constants.NB_LOGO),
              height: 20,
              width: 100,
            },
            {
              text: "North Battleford Report",
              style: "headerText",
            },
          ],
        },
        footer: function(currentPage, pageCount) {
          return {
            margin: 15,
            columns: [
              {
                text: "Page " + currentPage.toString() + " of " + pageCount,
                style: "footerText",
              },
            ],
          };
        },
        content: [
          {
            text: "Parking Violations - Weekly Status Report",
            style: "heading",
          },
          {
            text:
              "(" +
              self.$moment(new Date()).format("MMMM Do YYYY, h:mm a") +
              ")",
            style: "subheading1",
          },
          {
            text: "Parking Violations Outstanding more than 21 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "auto"],
              body: this.buildTableBody(
                columns1,
                this.violations21DaysOld,
                this.getFineAmt,
                this.getColorScheme
              ),
            },
          },
          {
            text: "Parking Violations Outstanding more than 14 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "auto"],
              body: this.buildTableBody(
                columns2,
                this.violations14To21DaysOld,
                this.getFineAmt,
                this.getColorScheme
              ),
            },
          },
          {
            text: "Parking Violations Outstanding less than 14 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "auto"],
              body: this.buildTableBody(
                columns3,
                this.violations14DaysOld,
                this.getEarlyAmt,
                this.getColorScheme
              ),
            },
          },
        ],
        styles: commonjs.reportStyles,
      };
      pdfMake.createPdf(docDefinition).open();
    },

    buildTableBody(columns, data, calculateFine, colorScheme) {
      let body = [];
      let headerArr = [];

      columns.forEach((col) =>
        headerArr.push({
          text: col,
          style: "tableHeader",
          fillColor: colorScheme.lightColor,
        })
      );

      body.push(headerArr);

      data.forEach(function(row) {
        let dataRow = [];
        dataRow.push({ text: row.ticketNumber, style: "tableBody" });
        dataRow.push({ text: row.licensePlate, style: "tableBody" });
        dataRow.push({ text: row.dateIssued, style: "tableBody" });
        dataRow.push({
          text: "$" + calculateFine(row.violationTypes),
          style: "tableBody",
        });
        body.push(dataRow);
      });

      return body;
    },
  },
};
</script>
