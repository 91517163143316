<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col lg="8" md="8" sm="12" xs="12">
        <v-card outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col
                lg="8"
                md="8"
                sm="12"
                xs="12"
                class="d-flex align-center justify-start pb-3"
              >
                <p class="title font-weight-bold ma-0">
                  Yard Orders - Weekly Status Report
                </p>
                <p class="caption font-weight-bold ma-0 ml-2">
                  {{
                    "(" +
                      $moment(new Date()).format("MMMM Do YYYY, h:mm a") +
                      ")"
                  }}
                </p>
              </v-col>
              <v-col class="d-flex align-right justify-end">
                <v-btn text small raised @click="generatePDF">
                  Print
                  <v-icon color="#B00020">
                    mdi-file-pdf-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6">
                <p class="subtitle font-weight-bold mb-1">
                  Yard Orders Outstanding more than 21 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="yardOrders21DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.orderNumber`]="{ item }">
                    <span class="caption">{{ item.orderNumber }}</span>
                  </template>
                  <template v-slot:[`item.byLawNumber`]="{ item }">
                    <span class="caption">{{ item.byLawNumber }}</span>
                  </template>
                  <template v-slot:[`item.dateIssued`]="{ item }">
                    <span class="caption">{{ item.dateIssued }}</span>
                  </template>
                  <template v-slot:[`item.orderStatus`]="{ item }">
                    <span class="caption">
                      {{ item.orderStatus.status }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" class="pt-5">
                <p class="subtitle font-weight-bold mb-1">
                  Yard Orders Outstanding more than 14 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="yardOrders14To21DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.orderNumber`]="{ item }">
                    <span class="caption">{{ item.orderNumber }}</span>
                  </template>
                  <template v-slot:[`item.byLawNumber`]="{ item }">
                    <span class="caption">{{ item.byLawNumber }}</span>
                  </template>
                  <template v-slot:[`item.dateIssued`]="{ item }">
                    <span class="caption">{{ item.dateIssued }}</span>
                  </template>
                  <template v-slot:[`item.orderStatus`]="{ item }">
                    <span class="caption">
                      {{ item.orderStatus.status }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" class="pt-5">
                <p class="subtitle font-weight-bold mb-1">
                  Yard Orders Outstanding less than 14 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="yardOrders14DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.orderNumber`]="{ item }">
                    <span class="caption">{{ item.orderNumber }}</span>
                  </template>
                  <template v-slot:[`item.byLawNumber`]="{ item }">
                    <span class="caption">{{ item.byLawNumber }}</span>
                  </template>
                  <template v-slot:[`item.dateIssued`]="{ item }">
                    <span class="caption">{{ item.dateIssued }}</span>
                  </template>
                  <template v-slot:[`item.orderStatus`]="{ item }">
                    <span class="caption">
                      {{ item.orderStatus.status }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as commonjs from "../../shared/common";
import * as constants from "../../shared/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "yardorder-weekly-report",
  data() {
    return {
      headers: [
        {
          text: "Order #",
          value: "orderNumber",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "ByLaw Number",
          value: "byLawNumber",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Date of Issue",
          value: "dateIssued",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Order Status",
          value: "orderStatus",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
      yardOrders: [],
      yardOrders21DaysOld: [],
      yardOrders14To21DaysOld: [],
      yardOrders14DaysOld: [],
    };
  },
  async created() {
    await this.getYardOrdersAction();
    this.yardOrders = this.$store.state.yardOrders;
    await this.filterResults();
  },
  computed: {
    ...mapGetters(["getColorScheme"]),
  },
  methods: {
    ...mapActions(["getYardOrdersAction"]),

    filterResults() {
      let dateSub21Days = this.$moment(new Date(), "MMM Do YYYY")
        .subtract(21, "days")
        .toISOString()
        .substr(0, 10);
      let dateSub14Days = this.$moment(new Date(), "MMM Do YYYY")
        .subtract(14, "days")
        .toISOString()
        .substr(0, 10);

      //filter results based on Issue date
      this.yardOrders21DaysOld = this.yardOrders.filter(
        (yardorder) =>
          yardorder.isWarningOnly == false &&
          this.$moment(yardorder.dateIssued).isBefore(dateSub21Days)
      );

      this.yardOrders14To21DaysOld = this.yardOrders.filter(
        (yardorder) =>
          yardorder.isWarningOnly == false &&
          this.$moment(yardorder.dateIssued).isSameOrAfter(dateSub21Days) &&
          this.$moment(yardorder.dateIssued).isSameOrBefore(dateSub14Days)
      );

      this.yardOrders14DaysOld = this.yardOrders.filter(
        (yardorder) =>
          yardorder.isWarningOnly == false &&
          this.$moment(yardorder.dateIssued).isAfter(dateSub14Days)
      );
    },

    async generatePDF() {
      let self = this;
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let columns = [];
      this.headers.forEach((col) => columns.push(col.text));

      var docDefinition = {
        pageMargins: [25, 50, 25, 50],
        header: {
          margin: 25,
          columns: [
            {
              image: await commonjs.getBase64ImageFromURL(constants.NB_LOGO),
              height: 20,
              width: 100,
            },
            {
              text: "North Battleford Report",
              style: "headerText",
            },
          ],
        },
        footer: function(currentPage, pageCount) {
          return {
            margin: 15,
            columns: [
              {
                text: "Page " + currentPage.toString() + " of " + pageCount,
                style: "footerText",
              },
            ],
          };
        },
        content: [
          {
            text: "Yard Orders - Weekly Status Report",
            style: "heading",
          },
          {
            text:
              "(" +
              self.$moment(new Date()).format("MMMM Do YYYY, h:mm a") +
              ")",
            style: "subheading1",
          },
          {
            text: "Yard Orders Outstanding more than 21 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],
              body: this.buildTableBody(
                columns,
                this.yardOrders21DaysOld,
                this.getColorScheme
              ),
            },
          },
          {
            text: "Yard Orders Outstanding more than 14 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],
              body: this.buildTableBody(
                columns,
                this.yardOrders14To21DaysOld,
                this.getColorScheme
              ),
            },
          },
          {
            text: "Yard Orders Outstanding less than 14 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],
              body: this.buildTableBody(
                columns,
                this.yardOrders14DaysOld,
                this.getColorScheme
              ),
            },
          },
        ],
        styles: commonjs.reportStyles,
      };
      pdfMake.createPdf(docDefinition).open();
    },

    buildTableBody(columns, data, colorScheme) {
      let body = [];
      let headerArr = [];

      columns.forEach((col) =>
        headerArr.push({
          text: col,
          style: "tableHeader",
          fillColor: colorScheme.lightColor,
        })
      );

      body.push(headerArr);

      data.forEach(function(row) {
        let dataRow = [];
        dataRow.push({ text: row.orderNumber, style: "tableBody" });
        dataRow.push({ text: row.byLawNumber, style: "tableBody" });
        dataRow.push({ text: row.dateIssued, style: "tableBody" });
        dataRow.push({ text: row.orderStatus.status, style: "tableBody" });
        body.push(dataRow);
      });

      return body;
    },
  },
};
</script>
