<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col lg="8" md="8" sm="12" xs="12">
        <v-card outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col
                lg="8"
                md="8"
                sm="12"
                xs="12"
                class="d-flex align-center justify-start pb-3"
              >
                <p class="title font-weight-bold ma-0">
                  Customer Complaints - Weekly Status Report
                </p>
                <p class="caption font-weight-bold ma-0 ml-2">
                  {{
                    "(" +
                      $moment(new Date()).format("MMMM Do YYYY, h:mm a") +
                      ")"
                  }}
                </p>
              </v-col>
              <v-col class="d-flex align-right justify-end">
                <v-btn text small raised @click="generatePDF">
                  Print
                  <v-icon color="#B00020">
                    mdi-file-pdf-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6">
                <p class="subtitle font-weight-bold mb-1">
                  Customer Complaints Outstanding more than 21 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="complaints21DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.complaintNumber`]="{ item }">
                    <span class="caption">{{ item.complaintNumber }}</span>
                  </template>
                  <template v-slot:[`item.complaint_type`]="{ item }">
                    <span class="caption">{{ item.complaint_type.type }}</span>
                  </template>
                  <template v-slot:[`item.complaintDate`]="{ item }">
                    <span class="caption">{{ item.complaintDate }}</span>
                  </template>
                  <template v-slot:[`item.complaint_status`]="{ item }">
                    <span class="caption">
                      {{ item.complaint_status.status }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" class="pt-5">
                <p class="subtitle font-weight-bold mb-1">
                  Customer Complaints Outstanding more than 14 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="complaints14To21DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.complaintNumber`]="{ item }">
                    <span class="caption">{{ item.complaintNumber }}</span>
                  </template>
                  <template v-slot:[`item.complaint_type`]="{ item }">
                    <span class="caption">{{ item.complaint_type.type }}</span>
                  </template>
                  <template v-slot:[`item.complaintDate`]="{ item }">
                    <span class="caption">{{ item.complaintDate }}</span>
                  </template>
                  <template v-slot:[`item.complaint_status`]="{ item }">
                    <span class="caption">
                      {{ item.complaint_status.status }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="4" md="4" sm="6" class="pt-5">
                <p class="subtitle font-weight-bold mb-1">
                  Customer Complaints Outstanding less than 14 days
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="complaints14DaysOld"
                  class="elevation-1"
                  dense
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.complaintNumber`]="{ item }">
                    <span class="caption">{{ item.complaintNumber }}</span>
                  </template>
                  <template v-slot:[`item.complaint_type`]="{ item }">
                    <span class="caption">{{ item.complaint_type.type }}</span>
                  </template>
                  <template v-slot:[`item.complaintDate`]="{ item }">
                    <span class="caption">{{ item.complaintDate }}</span>
                  </template>
                  <template v-slot:[`item.complaint_status`]="{ item }">
                    <span class="caption">
                      {{ item.complaint_status.status }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as commonjs from "../../shared/common";
import * as constants from "../../shared/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "complaint-weekly-report",
  data() {
    return {
      headers: [
        {
          text: "Complaint #",
          value: "complaintNumber",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Complaint Type",
          value: "complaint_type",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Complaint Date",
          value: "complaintDate",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Complaint Status",
          value: "complaint_status",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
      complaints: [],
      complaints21DaysOld: [],
      complaints14To21DaysOld: [],
      complaints14DaysOld: [],
    };
  },
  async created() {
    await this.getComplaintsAction();
    this.complaints = this.$store.state.complaints;

    await this.filterResults();
  },
  computed: {
    ...mapGetters(["getColorScheme"]),
  },
  methods: {
    ...mapActions(["getComplaintsAction"]),

    filterResults() {
      let dateSub21Days = this.$moment(new Date(), "MMM Do YYYY")
        .subtract(21, "days")
        .toISOString()
        .substr(0, 10);
      let dateSub14Days = this.$moment(new Date(), "MMM Do YYYY")
        .subtract(14, "days")
        .toISOString()
        .substr(0, 10);

      //filter results based on Complaint date
      this.complaints21DaysOld = this.complaints.filter((complaint) =>
        this.$moment(complaint.complaintDate).isBefore(dateSub21Days)
      );

      this.complaints14To21DaysOld = this.complaints.filter(
        (complaint) =>
          this.$moment(complaint.complaintDate).isSameOrAfter(dateSub21Days) &&
          this.$moment(complaint.complaintDate).isSameOrBefore(dateSub14Days)
      );

      this.complaints14DaysOld = this.complaints.filter((complaint) =>
        this.$moment(complaint.complaintDate).isAfter(dateSub14Days)
      );
    },

    async generatePDF() {
      let self = this;
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let columns = [];
      this.headers.forEach((col) => columns.push(col.text));

      var docDefinition = {
        pageMargins: [25, 50, 25, 50],
        header: {
          margin: 25,
          columns: [
            {
              image: await commonjs.getBase64ImageFromURL(constants.NB_LOGO),
              height: 20,
              width: 100,
            },
            {
              text: "North Battleford Report",
              style: "headerText",
            },
          ],
        },
        footer: function(currentPage, pageCount) {
          return {
            margin: 15,
            columns: [
              {
                text: "Page " + currentPage.toString() + " of " + pageCount,
                style: "footerText",
              },
            ],
          };
        },
        content: [
          {
            text: "Customer Complaints - Weekly Status Report",
            style: "heading",
          },
          {
            text:
              "(" +
              self.$moment(new Date()).format("MMMM Do YYYY, h:mm a") +
              ")",
            style: "subheading1",
          },
          {
            text: "Customer Complaints Outstanding more than 21 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],
              body: this.buildTableBody(
                columns,
                this.complaints21DaysOld,
                this.getColorScheme
              ),
            },
          },
          {
            text: "Customer Complaints Outstanding more than 14 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],
              body: this.buildTableBody(
                columns,
                this.complaints14To21DaysOld,
                this.getColorScheme
              ),
            },
          },
          {
            text: "Customer Complaints Outstanding less than 14 days",
            style: "subheading",
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],
              body: this.buildTableBody(
                columns,
                this.complaints14DaysOld,
                this.getColorScheme
              ),
            },
          },
        ],
        styles: commonjs.reportStyles,
      };
      pdfMake.createPdf(docDefinition).open();
    },

    buildTableBody(columns, data, colorScheme) {
      let body = [];
      let headerArr = [];

      columns.forEach((col) =>
        headerArr.push({
          text: col,
          style: "tableHeader",
          fillColor: colorScheme.lightColor,
        })
      );

      body.push(headerArr);

      data.forEach(function(row) {
        let dataRow = [];
        dataRow.push({ text: row.complaintNumber, style: "tableBody" });
        dataRow.push({ text: row.complaint_type.type, style: "tableBody" });
        dataRow.push({ text: row.complaintDate, style: "tableBody" });
        dataRow.push({ text: row.complaint_status.status, style: "tableBody" });
        body.push(dataRow);
      });

      return body;
    },
  },
};
</script>
